import React from "react";
import { Field, useField } from "formik";
import {
  format,
  decimal,
  money,
  percentage,
  other,
  ccard,
} from "../../utils/numeric-store";

const libfunc = {
  money: money,
  other: other,
  percentage: percentage,
  ccard: ccard,
};
const Numeric = ({ props }) => {
  const [field] = useField(props.name);
  const detail = props.detail ?? {};
  const configTextfield = {
    ...field,
    label: props.label,
    placeholder: props.placeholder,
  };

  const onlyNumbers = (e) => {
    let size = detail.max;
    let x = e.target.value;
    x = x.replace(/\D/g, "");

    if (detail.mask && detail.decimal) {
      if (size && x.length > size + detail.decimal) {
        x = x.slice(0, size + detail.decimal);
      }
      e.target.value =
        detail.decimal && detail.decimal > 0 ? decimal(x, detail.decimal) : x;
    } else {
      e.target.value = x > size ? x.slice(0, size) : x;
    }

    e.target.value =
      e.target.value !== "."
        ? parseFloat(e.target.value).toFixed(detail.decimal)
        : e.target.value;
    field.onChange(e);
  };

  const mask = (e) => {
    let x = e.target.value;

    if (detail.split) {
      let num = x.replace(/\D/g, "");
      x = format(num, detail);
    }
    if (detail.mask && detail.mask !== "numeric") {
      const reset = libfunc[[detail.mask]];
      e.target.value = reset(x, detail);
    }
  };

  return (
    <>
      <Field
        {...configTextfield}
        onChange={(e) => onlyNumbers(e)}
        onBlur={(e) => mask(e)}
      />
    </>
  );
};
export default Numeric;
