import * as Yup from "yup";

function schema(validations, _type, data) {
  let y;
  switch (_type) {
    case "number":
      y = Yup.number();
      break;
    case "date":
      y = Yup.date();
      break;
    default:
      y = Yup.string();
      break;
  }

  validations.forEach(({ type, params }) => {
    switch (type) {
      case "integer":
        y = y.integer().typeError(params[0].err);
        break;
      case "email":
        y = y.email(params[0].err);
        break;
      case "boolean":
        y = Yup.bool()
          .transform((_, val) => (val.length == 1 ? true : false))
          .oneOf([true], params[0].err)
          .required(params[0].err);

        break;
      case "requerid":
        if (_type === "select") {
          y = y.required(params[0].err).oneOf(data, params[0].err);
        } else {
          y = y.required(params[0].err);
        }

        break;
      case "min":
        y = y.min(params[0], params[1]);
        break;
      case "max":
        y = y.max(params[0], params[1]);
        break;
    }
  });

  return y;
}

function getYup(schemas) {
  return Yup.object().shape(schemas);
}

export { schema, getYup };
