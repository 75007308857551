import React from "react";
import { Button as _Button } from "@mui/material";

const Button = ({ btn, btnNext, btnBefore, dirty, isValid }) => {
  const disabled =
    !(dirty && isValid) && (btn.styleClassName ?? "").includes("primary");

  const getClasses = () => {
    if (btn.styleClassName) {
      let _class = [];
      _class.push(btn.styleClassName);
      if (disabled) _class.push("merchant-disabel");
      return _class.join(" ").trim();
    }

    return "merchant-primary";
  };

  return (
    <_Button
      color="secondary"
      variant="contained"
      fullWidth
      className={getClasses()}
      type={btn.type}
      name={btn.name}
      onClick={btn.name === "next" ? btnNext : btnBefore}
      style={btn.styles}
      disabled={disabled}
    >
      {btn.label}
    </_Button>
  );
};
export default Button;
