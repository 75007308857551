import React from "react";
import { Field, useField } from "formik";

const Text = ({ props }) => {
  const [field] = useField(props.name);

  const configTextField = {
    ...field,
    label: props.label,
    placeholder: props.placeholder,
    style: props.style,
    maxLength: props.maxLength,
    minLength: props.minLength,
    name: props.name,
    id: props.id,
    type: props.type,
    title: props.title,
    readOnly: props.readOnly,
  };
  configTextField.value = configTextField.value ?? "";

  return <Field {...configTextField} />;
};

export default Text;
