import Telephone from "./inputs/telephone";
import Text from "./inputs/text";
import Select from "./inputs/select";
import Column from "./inputs/column";
import Code from "./inputs/code";
import Numeric from "./inputs/numeric";
import Radiobutton from "./inputs/radioButton";
import CheckBox from "./inputs/checkbox";
import DateField from "./inputs/date";
import UploadFile from "./inputs/uploadFileArea";
import UploadFileCard from "./inputs/uploadFileCard";
import TextFieldMaterial from "./inputs/textFieldMaterial";

const inputsComponents = {
  telephone: Telephone,
  text: Text,
  select: Select,
  column: Column,
  code: Code,
  numeric: Numeric,
  radiobutton: Radiobutton,
  checkbox: CheckBox,
  date: DateField,
  uploadfile: UploadFile,
  uploadfilecard: UploadFileCard,
  textField: TextFieldMaterial,
};

const Input = ({ type, data }) => {
  const InputsComponent = inputsComponents[[type]];

  if (type == "uploadfileCard");

  if (!InputsComponent) {
    return null;
  }

  return <InputsComponent key={data.id} {...data} />;
};

const GenericInput = (props) => {
  return <Input type={props.props.type} data={props} />;
};

export default GenericInput;
