import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import InputMask from "react-input-mask";

const DateField = ({ props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  registerLocale("es", es);

  return (
    <DatePicker
      selected={(field.value && new Date(field.value)) || null}
      placeholderText={props.placeholder}
      dateFormat="dd/MM/yyyy"
      onChange={(val) => {
        setFieldValue(field.name, val);
      }}
      locale="es"
      dropdownMode="select"
      showMonthDropdown
      showYearDropdown
      adjustDateOnChange
      autoComplete="nope"
      customInput={
        <InputMask
          mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
        />
      }
    />
  );
};

export default DateField;
