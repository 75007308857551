import {
  PersonOutline,
  StoreMallDirectory,
  InsertDriveFileOutlined,
} from "@mui/icons-material";

const Icons = (icon) => {
  switch (icon) {
    case "PersonOutlineIcon":
      return <PersonOutline />;
    case "StoreMallDirectoryIcon":
      return <StoreMallDirectory />;
    case "InsertDriveFileOutlinedIcon":
      return <InsertDriveFileOutlined />;
    default:
      return <div></div>;
  }
};

export { Icons };
