import React from "react";
import { Field, useField } from "formik";
import themeKym from "../../utils/themes/ThemeKym";

const Radiobutton = ({ props, radioOptions, eventRadioBtn }) => {
  const classesKym = themeKym();
  const [field] = useField(props.name);

  const onchangeRadio = (e) => {
    props.value = e.target.value;
    eventRadioBtn(props.name, e.target.value);
    field.onChange(e);
  };

  return (
    <div role="group" id="radioBtn" className={classesKym.Container}>
      {radioOptions.map((opt, i) => {
        const configTextField = {
          ...field,
          name: props.name,
          value: opt.value,
          checked: props.value == opt.value,
          className: classesKym.Input,
        };

        return (
          <div className={classesKym.Card + " rCard"} key={i}>
            <label className={classesKym.RadioButtonLbl}>
              <Field
                type="radio"
                {...configTextField}
                onChange={(e) => onchangeRadio(e)}
              />
              <span className="radioLabel">{opt.label}</span>
              <span className="radioDescription"> {opt.description} </span>
            </label>
            <label className={classesKym.RadioSubtitle}>{opt.subtitle}</label>
          </div>
        );
      })}
    </div>
  );
};

export default Radiobutton;
