import React from "react";
import { Field, useField } from "formik";

const CheckBox = ({ props }) => {
  const [field] = useField(props.name);
  const configTextField = {
    placeholder: props.placeholder,
    type: "checkbox",
    name: field.name,
    value: field.name,
    id: props.id,
  };

  return (
    <table>
      <tbody>
        <tr>
          <td style={{ verticalAlign: "top", padding: "0 10px 0 10px" }}>
            <Field {...configTextField} />
          </td>
          <td align="justify">{configTextField.placeholder}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default CheckBox;
