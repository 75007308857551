import React, { useState, useEffect, Fragment } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as FindIcon } from "../../assets/generic/PendingValidate.svg";
import { ReactComponent as TrashIcon } from "../../assets/icon/trash.svg";
import { ReactComponent as EditIcon } from "../../assets/icon/edit.svg";
import Typography from "@mui/material/Typography";

const BasicTable = ({
  data,
  columns,
  handleEdit,
  handleDelete,
  align = "center",
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowData, setRowData] = useState(data);

  useEffect(() => {
    setRowData(data);
    setPage(0);
  }, [data]);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const deleteRow = async (i) => {
    setRowData([]);
    await handleDelete(i);
    setRowData(data);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <div style={{ margin: "15px" }}>
        <Paper
          sx={{
            boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
            borderRadius: "16px",
          }}
        >
          <TableContainer>
            <Table
              sx={{ minWidth: 650, position: "relative" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  {columns?.map((col, idx) => (
                    <TableCell
                      key={idx}
                      align={align}
                      style={{ fontWeight: 600, fontSize: "14px" }}
                    >
                      {col}
                    </TableCell>
                  ))}
                  {(handleEdit || handleDelete) && <TableCell />}
                </TableRow>
              </TableHead>

              {rowData?.length == 0 ? (
                <TableBody className="no-information-container">
                  <TableRow className="no-information-message">
                    <TableCell></TableCell>
                    <TableCell width={"100%"} style={{ textAlign: "center" }}>
                      <FindIcon style={{ width: "20%" }} />
                      <Typography
                        variant="h5"
                        gutterBottom
                        component="div"
                        sx={{
                          fontWeight: 700,
                          fontSize: "20px",
                          color: "#343C46",
                        }}
                      >
                        Aún no tienes socios agregados
                      </Typography>
                      <Typography
                        variant="h5"
                        gutterBottom
                        component="div"
                        sx={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "#343C46",
                        }}
                      >
                        Agrega a los socios para continuar el registro de tu
                        negocio
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {rowData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      return (
                        <TableRow key={uuidv4()}>
                          {Object.keys(row).map((cell, index) => {
                            return (
                              <Fragment key={uuidv4()}>
                                <TableCell key={uuidv4()} align={align}>
                                  {row[cell]}
                                </TableCell>
                              </Fragment>
                            );
                          })}
                          {(handleEdit || handleDelete) && (
                            <TableCell>
                              {handleDelete && (
                                <TrashIcon
                                  onClick={() => deleteRow(i)}
                                  style={{ marginRight: 30 }}
                                  data-testid={"trashIcon" + i}
                                />
                              )}
                              {handleEdit && (
                                <EditIcon
                                  onClick={() => handleEdit(row, i)}
                                  data-testid={"editIcon" + i}
                                />
                              )}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {rowData.length > 10 && (
            <TablePagination
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </div>
    </div>
  );
};

export default BasicTable;
