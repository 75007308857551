import { useState } from "react";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function UploadFileCard(data) {
  const [file, setFile] = useState(null);
  function internalOnChange(event) {
    setFile(null);
    setFile(event.target.files[0]);
    data.setFieldValue(data.props.name, event.currentTarget.files[0]);
    data.props.onChange(event);
  }

  return (
    <div
      style={{
        padding: "25px 16px",
        marginTop: "15px",
        backgroundColor: "#fff",
        width: "150%",
        height: "70px",
        borderRadius: "4px",
        border: "1px solid #CFD0DF",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <CheckCircleIcon
        style={{
          color: file ? "#66BC6B" : "#CFD0DF",
          fontSize: "20px",
          marginRight: 15,
        }}
      />
      <div style={{ flex: 1 }}>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{
            fontWeight: 700,
            fontSize: "12px",
            color: "#343C46",
            float: "left",
          }}
        >
          {data.props.title}
        </Typography>
      </div>

      <div style={{ position: "relative" }}>
        {!file ? (
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            sx={{
              fontSize: "12px",
            }}
          >
            <a
              style={{
                color: "#363853",
                backgroundColor: "#FABE00",
                padding: "12px",
                textDecoration: "none",
                borderRadius: "4px",
                fontWeight: 700,
              }}
              href="# "
            >
              Subir archivo
            </a>
          </Typography>
        ) : (
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            sx={{
              fontSize: "12px",
            }}
          >
            <a
              style={{
                color: "#363853",
                backgroundColor: "#FFF",
                padding: "12px",
                textDecoration: "none",
                borderRadius: "4px",
                fontWeight: 700,
                border: "1px solid #FABE00",
              }}
              href="# "
            >
              Modificar
            </a>
          </Typography>
        )}
        <input
          data-testid={"InputFile"}
          id="file-id"
          name={data.name}
          required
          type="file"
          multiple
          accept="image/*"
          style={{
            position: "absolute",
            margin: "0",
            padding: "0",
            width: "110px",
            height: "38px",
            opacity: "0",
            outline: "none",
            cursor: "pointer",
            left: 0,
            top: "-11px",
            background: "red",
          }}
          onChange={internalOnChange}
        />
      </div>
    </div>
  );
}
