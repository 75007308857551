import { Card, Grid } from "@mui/material";
import React, { Fragment } from "react";
import { Icons } from "../../utils/icons-material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { ReactComponent as TrashIcon } from "../../assets/icon/trash.svg";
import Typography from "@mui/material/Typography";

const CardForm = (props) => {
  return (
    <Fragment>
      {props.data.map((card, i) => (
        <Card
          key={i}
          style={{
            padding: 20,
            borderRadius: 8,
            boxShadow: "0px 4px 12px rgba(194, 209, 217, 0.46)",
            position: "relative",
            marginBottom: 15,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={1} style={{ verticalAlign: "center" }}>
              {Icons(card?.icon)}
            </Grid>
            <Grid item xs={11}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{
                  fontWeight: 700,
                  fontSize: "14px",
                  color: "#404A56",
                  textAlign: "left",
                }}
              >
                {card?.title}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{ marginTop: card?.subtitle2 ? 10 : 30 }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#404A56",
                  textAlign: "left",
                }}
              >
                {card?.subtitle}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#404A56",
                  textAlign: "left",
                }}
              >
                {card.subtitle2 ? card.subtitle2 : ""}
              </Typography>
            </Grid>
          </Grid>
          <div style={{ position: "absolute", right: 20, top: 20 }}>
            <BorderColorOutlinedIcon
              data-testid={"btn-edit-" + i}
              onClick={() => props.handleEdit(i)}
              style={{ marginRight: 12 }}
            />
            <TrashIcon
              data-testid={"btn-delete-" + i}
              onClick={() => props.handleDelete(i)}
            />
          </div>
        </Card>
      ))}
    </Fragment>
  );
};

export default CardForm;
