import { default as btn } from "./inputs/button";
import Submit from "./inputs/submit";

const buttonsComponents = {
  button: btn,
  submit: Submit,
};

const Button = ({ type, data, btnBefore, btnNext, submit, dirty, isValid }) => {
  const ButtonsComponent = buttonsComponents[[type]];
  if (!ButtonsComponent) {
    return null;
  }
  return (
    <ButtonsComponent
      btn={data}
      btnBefore={btnBefore}
      btnNext={btnNext}
      submit={submit}
      dirty={dirty}
      isValid={isValid}
    />
  );
};

const GenericButton = ({
  props,
  btnBefore,
  btnNext,
  submit,
  dirty,
  isValid,
}) => {
  return (
    <Button
      type={props.type}
      data={props}
      btnBefore={btnBefore}
      btnNext={btnNext}
      submit={submit}
      dirty={dirty}
      isValid={isValid}
    />
  );
};

export default GenericButton;
