import React from "react";
import { ErrorMessage as Err } from "formik";

const ErrorMessage = ({ name, errors }) => {
  return (
    <Err
      name={name}
      component={() => <div className="error">{errors[name]}</div>}
    />
  );
};

export default ErrorMessage;
